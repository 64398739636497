import type { ContentfulCollection } from 'contentful';

import {
  ContentfulIcon,
  EventMerchandise,
  EventVariant,
} from '@/types/contentful/index';

export type EventsAndMerchandisesResponse = {
  simpleEventCollection: {
    items: EventItem[];
  };
  merchandiseCollection: {
    items: EventMerchandise[];
  };
};

export interface RawEventVariant extends EventVariant {
  iconsCollection: ContentfulCollection<ContentfulIcon>;
}

export type RawEvent = {
  variantsCollection: ContentfulCollection<RawEventVariant>;
  merchandisesCollection: ContentfulCollection<EventMerchandise>;
};

export type EventItem = {
  poster: {
    asset: {
      url: string;
    };
  };
  shopifyProductId: string;
  title: string;
  variants: EventVariant[];
  merchandises: EventMerchandise[];
};

export const eventsWithVariantsAndMerchandisesQuery = `
    query merchandisesAndEvents($eventIds: [String!], $addonIds: [String!]) {
        merchandiseCollection(
            where: { shopifyProductId_in: $addonIds }
            limit: 10
        ) {
            items {
                title
                shopifyProductId
                shopifyVariantId
                price {
                    EUR: eur
                    USD: usd
                    AUD: aud
                    GBP: gbp
                }
                compareAtPrice {
                    EUR: eur
                    USD: usd
                    AUD: aud
                    GBP: gbp
                }
                poster {
                    alt
                    asset {
                        url
                    }
                }
            }
        }
        simpleEventCollection(where: { shopifyProductId_in: $eventIds }, limit: 10) {
            items {
                title
                shopifyProductId
                showStartTime
                showEndTime
                doorsOpenTime
                variantComplexCollection(limit: 2) {
                    items {
                        sys {
                            id
                        }
                        price {
                            title
                            eur
                            usd
                            gbp
                            aud
                        }
                        stream {
                            title
                            hlsUrl
                            dashUrl
                            duration
                            dashAppUrl
                            dashCastUrl
                        }
                        compareAtPrice {
                            title
                            eur
                            usd
                            gbp
                            aud
                        }
                        iconsCollection(limit: 5) {
                            items {
                            sys {
                                id
                            }
                            type
                            }
                        }
                        eventStart
                        doorsOpen
                        saleEnd
                        eventEnd
                        sku
                        shopifyProductVariantId
                        optionsCollection(limit: 5) {
                            items {
                                sys {
                                    id
                                }
                                value
                                position
                            }
                        }
                    }
                  }
                variantsCollection(limit: 2) {
                    items {
                      sys {
                        id
                      }
                      shopifyProductVariantId
                      title
                      sku
                      quality {
                        displayLabel
                        horizontalQualityIconsCollection {
                          items {
                            type
                          }
                        }
                        verticalQualityIconsCollection {
                          items {
                            type
                          }
                        }
                      }
                      price {
                        title
                        eur
                        usd
                        gbp
                        aud
                      }
                      compareAtPrice {
                        title
                        eur
                        usd
                        gbp
                        aud
                      }
                    }
                  }
                portraitImage {
                    asset {
                        title
                        description
                        url
                        width
                        height
                    }
                    
                }
                landscapeImage {
                     asset {
                        title
                        description
                        url
                        width
                        height
                    }
                  }
                merchandisesCollection {
                    items {
                        title
                        shopifyProductId
                        shopifyVariantId
                        whitelistedCountryGroupsCollection {
                            items {
                                title
                                countries
                            }
                        }
                        poster {
                            asset {
                                url
                            }
                            alt
                        }
                        price {
                            EUR: eur
                            USD: usd
                            AUD: aud
                            GBP: gbp
                        }
                        compareAtPrice {
                            EUR: eur
                            USD: usd
                            AUD: aud
                            GBP: gbp
                        }
                    }
                }
            }
        }
    }
`;
